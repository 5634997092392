.design{
    /* border: 2px solid red; */
    
    padding-top: 30rem;
    padding-bottom: 5rem;
    background:conic-gradient(#E8662B 0deg 90deg, white 90deg 270deg, #E8662B 270deg);
}
.design-container{
    border: 1px solid #F2F2F2;
    padding: 0 3rem;
    padding-bottom: 3rem;
    background: #F2F2F2;
    display: flex;
     flex-direction: column;
     flex-wrap: wrap;

}

.design-image{
width: 80rem;
height: 60rem;
max-width: 80rem;
/* border: 2px solid red; */
display: block;
margin: auto;
margin-top: -15rem;

}
.design-image img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
.design-heading h1{
    font-size: 4rem;
    text-align: center;
    margin: 3rem 0;
    font-family:fantasy;
    font-weight:lighter;
    text-transform: capitalize;
}
.design-text p {
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
    margin-bottom: 3rem;
}
.btn-design{
    
    font-size: 1.5rem;
    padding: 1.5rem 3rem;
    background-color:#E8662B;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5rem;
    border: none;
    outline: none;
    cursor: pointer;
    display: block;
    margin: auto;
}

/* Media Queries */

@media(max-width:900px){

   
    .design-image{
        width: 60rem;
        height: 60rem;
        max-width: 60rem;
        /* border: 2px solid red; */
        display: block;
        margin: auto;
        margin-top: -15rem;
        
        }
}

@media(max-width:720px){

   
    .design-image{
        width: 40rem;
        height: 40rem;
        max-width: 40rem;
        /* border: 2px solid red; */
        display: block;
        margin: auto;
        margin-top: -15rem;
        
        }
        .design-heading h1{
            font-size: 3rem;
         
        }
}

@media(max-width:450px){


    
    .design-image{
        width: 25rem;
        height: 30rem;
        max-width: 25rem;
        /* border: 2px solid red; */
        display: block;
        margin: auto;
        margin-top: -15rem;
        
        }
        .design-heading h1{
            font-size: 2rem;
         
        }
}