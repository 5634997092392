.header {
  /* border: 2px solid black; */
  background: conic-gradient(#394d60 180deg, #e8662b 180deg);
  /* height: 100vh; */
  padding-bottom: 10rem;
}
.header-container {
  /* border: 2px solid blue; */
  display: flex;
  /* padding: 4rem; */
}
.header-text {
  padding: 4rem;
  /* border: 2px solid red; */
  margin-right: -13rem;
  background-color: #394d60;
  color: white;
  width: 50rem;
  max-width: 50rem;
  height: 40rem;
  z-index: 2;
}
.header-text h2 {
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-bottom: 2rem;
}
.header-text h1 {
  font-size: 4rem;
  margin-bottom: 3rem;
}
.header-btn {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1rem;
  /* background-color: black; */
  border: none;
}
.header-btn a {
  text-decoration: none;
  color: orangered;
}
.header-image {
  margin-top: 10rem;
  /* border: 2px solid orange; */
  width: 90rem;
  height: 60rem;
  max-width: 90rem;
}
.header-image img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* ************ Media Queries ************** */

@media(max-width: 800px){
  .header-text h1 {
    font-size: 3rem;
   
  }
}

@media(max-width: 750px){
  .header-container {
    
    
    flex-wrap: wrap;
    
  }
  .header-text {
    margin-top: 10rem;
    margin-right: 0rem;
  }
   
   
  .header-image {
    margin-top: 0rem;
   
   
  }
}
@media(max-width:450px){
  .header-image {
    
    height: 40rem;
    
  }
}