.contact{
    /* border: 2px solid red; */
    background: #F2F2F2;
}
.contact-container{
    /* border: 2px solid blue; */
    display: flex;
    /* gap: 2rem; */
    /* flex-wrap: wrap; */
}
.contact-box{
    flex: 1 1 40rem;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    padding: 2rem;
    flex-wrap: wrap;
    /* background: #F2F2F2; */
        
}
.input-text{
padding: 2rem;
border: none;
border-bottom: 2px solid black;
outline: none;
background: #F2F2F2;

}
textarea{
    padding: 2rem;
    background: #F2F2F2;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.btn-contact{
    margin-top: 3rem;
    font-size: 1.5rem;
    padding: 1.5rem 3rem;
    background-color:#E8662B;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5rem;
    border: none;
    outline: none;
    cursor: pointer;
    /* display: block;
    margin: auto; */
}
.contact-info{
    flex: 1 1 40rem;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 2rem;
    background: white;
}
.contact-heading{
    font-size: 2rem;
    color:#E8662B;
    margin: 2rem 0;
}
.contact-call p{
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
.contact-location p{
    font-size: 1.5rem;
    line-height: 3rem;
    margin-bottom: 2rem;
}
.contact-top li{
    list-style: none;
}
.contact-image{
    
    flex: 1 1 40rem;
    /* border: 2px solid black; */
    /* max-width: 40rem; */
    /* height: 60rem; */
}
.contact-image img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}

/* ******* Media Queries ********* */

@media(max-width:750px){
    .contact-container{
        
        flex-wrap: wrap;
    }
}
