.footer{
    padding: 5rem 0;
    height: 10vh;
    /* border: 2px solid red; */
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
}
.foot-text {
    font-size: 0.6rem;
    color: #E8662B;
    font-weight: lighter;
    margin-top: 2rem;
    
}
.foot-text a{
    color: #E8662B ;
    font-size: 1.5rem;
    margin-top: 2rem;
    text-decoration: none;    
}